h1, h2, h3, h4, h5, h6, p{
  padding: 0px;
  margin: 0px;
}


html {
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
}

a{
  color: black;
  text-decoration: none;
}

a:hover{
  color: #ffc815 !important;
}

a:visited{
  color: black;
  text-decoration: none;
}

img {
  height: inherit;
}

ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

ul li {
  display: inline;
  padding-left: 20px;
  cursor: pointer;
}

nav {
  width: 100%;
}

.hamburger{
  cursor: pointer;
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.header-content-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

@media only screen and (max-width: 900px) {
  .hamburger {
    display: block;
  }

  .header-content-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 20px;
    margin-right: 40px;
  }

  .header-content-container{
    justify-content: space-between;
    padding: 0px;
  }

  .header-nav ul {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px;
    z-index: 98;
    opacity: 0;
    transform-origin: top center;
    transform: scaleY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .header-nav ul.open {
    display: block;
    flex-direction: column;
    justify-content: space-around;
    overflow: none;
    opacity: 1;
    transform: scaleY(1);
  }

  .header-nav li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    height: 80px;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .header-nav.open li {
    opacity: 1;
    transform: translateY(0);
  }

  .header-nav li:hover {
    background-color: rgb(255, 200, 21, 0.5);
    color: black;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .header-nav.open .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .header-nav.open .bar:nth-child(2) {
    opacity: 0;
  }

  .header-nav.open .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}
.align-center{
  align-items: center;
}

.space-between{
  justify-content: space-between;
}

.br-20{
  border-radius: 20px;
}

.h-inherit{
  height: inherit;
}

.gap-20{
  gap: 20px;
}

/* Create a container to hold the two columns */
.two-column-grid-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto;
  width: 80%;
}

/* Style the left column */
.left-column {
  flex: 1;
  margin-right: 10px;
}

/* Style the right column */
.right-column {
  flex: 1;
}

.header{
  position: fixed;
  background-color: white;
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  width: 100%;
  color: black;
  z-index: 100;
}

.horizontal-flex-container {
  display: flex;
  flex-direction: row;
}

.vertical-flex-container{
  display: flex;
  flex-direction: column;
}

.header-logo-container {
  gap: 10px;
}

.header-logo-container:hover{
  color: #ffc815;
}

.header-logo-img{
  height:50px;
}

.p-30{
  padding: 30px;
}

.header-logo-name{
  font-size: 1.5em;
}

.hero{
  text-align: center;
  height: 100vh;
  color: black;
}

.home-hero-text{
  position: relative;
  top: 35%;
  width: 90%;
  margin: 0 auto;
  background-color: white;
  border-radius: 20px;
  padding: 50px;
  width: 60%
}

.hero {
  --s: 300px; /* control the size*/
  --c1: #fde593e1;
  --c2: #dfdfdf;
  --c3: #ebebeb;
  
  background:
    conic-gradient(from  15deg at 86.6%,
      var(--c3) 25%,var(--c2) 0 150deg,#0000 0),
    conic-gradient(from -75deg at 50% 13.39%,
      var(--c1) 60deg,var(--c3) 0 150deg,
      var(--c2) 0 210deg,#0000 0),
    conic-gradient(from 15deg at 36.6% 63.4%,
     var(--c1) 60deg,var(--c3) 0 150deg,
     var(--c1) 0 210deg,var(--c2) 0 75%,var(--c3) 0);
  background-size: var(--s) var(--s);
}

.hero h1{
  font-size: 3.5em;
  margin-bottom: 50px;
  border-radius: 15px;
}
.hero h3{
  font-size: 1.2em;
}

.about{
  height: 100vh;
}

.experience{
  min-height: 100vh;
}

.projects{
  margin-bottom: 200px;
}

.section-title{
  text-decoration: underline;
  text-decoration-color: #ffc815;
  text-decoration-thickness: 5px;
  text-align: center;
  font-size: 2em;
  padding: 50px;
}

.left-column p{
  font-size: 1.2em;
}

.technology-stack-container {
  gap: 20px;
}

.technology-stack-container .svg-icon{
  height: 80px;
  padding:10px;
  box-sizing: border-box;
  background-color: #ffc815;
  border-radius: 15px;
}

.technology-stack-container img{
  width: 80px;
  border-radius: 15px;
}

footer{
  background-color: black;
  height: auto;
  color: white;
}


.footer-content-container{
  width: 50%;
  margin: 0 auto;
  padding-top: 50px;
}

.footer-copyright{
  text-align: center;
  padding: 10px;
  width: 70%;
  margin: 0 auto;
}

.footer-copyright p {
  padding-top: 10px;
  padding-bottom: 20px;
}

footer .svg-icon{
  width: 25px;
  height: 25px;
}

hr{
  border-color: white;
}

.projects-card-container{
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  gap: 50px;
}

.project-card{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 15px;
  height: 400px;
  width: 500px;
}

.project-card:hover{
  transform: translate(0px, -10px);
  transition: ease-in-out 200ms;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 30px;
}

.project-card-content{
  margin: 20px;
}

.project-card img{
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.project-card-links-group{
  position: relative;
  bottom: 0px;
}

.flex-wrap{
  flex-wrap: wrap;
}

.space-around{
  justify-content: space-around;
}

.space-evenly{
  justify-content: space-evenly;
}

.center{
  justify-content: center;
}

button{
  background-color: #ffc815;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 15px;
  width: 150px;
  margin: 5px;
  cursor: pointer;
}

button:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
  transition: ease-in 0.05s;
}

.aws-qualification-img{
  background-color: #ffc815;
}

.experience-item img{
  width: 80px;
  height: 80px;
  border-radius: 15px;
}

.experience-item{
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  padding: 15px;
  margin: 20px;
}

.experience-item-container{
  width: 60%;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .experience-item-container{
    width: 100%;
  }
  
}

#emp-text{
  background-color: #ffc815;
}

